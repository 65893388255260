import AppRoutes from "./Routes/AppRoutes";


function App() {
  return (
    <div className="custom-scrollbar">
    <AppRoutes /></div>
  );
}

export default App;
